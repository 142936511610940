
#login-button {
    box-sizing: border-box;
    font-family: spotify-circular, Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    background-color: transparent;
    border: 0px;
    border-radius: 1px;
    display: inline-block;
    position: relative;
    text-align: center;
    align-self: center;
    /* width: 9em; */
}
.ButtonInner {
    text-transform: uppercase;
    font-size: inherit;
    font-weight: bold;
    position: relative;
    background-color: var(--background-base, #1ed760);
    color: var(--text-base, #000000);
    border-radius: 500px;
    /* note: size of button */
    padding: 7px 32px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.item{
    margin-bottom: 2em;
}
