*,
*:after,
*:before {
  box-sizing: border-box;
}


.list {
  position: relative;
  /*width:20em;*/
  /*height:30em;*/
  /*direction: rtl;*/
  /*overflow:scroll;*/
  /*height: 37em;*/
  /*testing: experimenting with artist detail drawer on bottom?*/
  height:  25.5em;
  width:22.5em;
}

.list > div {
  position: absolute;
  will-change: transform, width, height, opacity;
  /*direction: rtl;*/
  /*padding: 15px;*/
  padding: 1px;
}


.list > div > div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  transition: transform 330ms ease-in-out;
  box-sizing: content-box;
  /*background-color:black;*/
}
.list > div > div:hover,
.list > div > div:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}


.bg {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 5.5em;
  height: 5.5em;
  background-size: cover;
  background-position: center;
  will-change: opacity;
  border:2px solid #6c6161d1;
}
