.filterItems{
    margin-left:.5em
}
.filterItems > div{
    margin-bottom:1em;
}

/*.filterItems div:first-child{*/
/*    margin-top:.5em;*/
/*}*/


.wrapper, html, body {
    height: 100%;
    margin: 0;
}
.wrapper {
    display: flex;
    flex-direction: column;
}

.tile-text{
    position: absolute;
    /*bottom: 0;*/
    left: 0;
    right: 0;
    text-align: center;
    font-size: .9em;
    color: white;
    padding: 5px;
    overflow: hidden;
    background: rgba(0, 0, 0, .6);
}


.artist-detail-text{
    position: absolute;
    left: 0;
    right: 0;
    font-size: .9em;
    color: white;
    padding: 5px;
    overflow: hidden;
    background: black;
}
