/*misc (anything in Events folder)*/

.display-playlist-params > div{
  margin-bottom: .5em
}

.MuiListItem-divider-123{
  border-bottom:none !important;
}

.MuiListItemText-inset-130:first-child{
  padding-left:0em !important;
}

/*testing: secondary action auto-floats to right*/

/*.rdp{*/
/*  font-size: 1rem;*/
/*  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;*/
/*  font-family: "cursive";*/
/*  font-weight: 400;*/
/*  line-height: 1.5;*/
/*  text-transform: none;*/
/*}*/

:root{
  /*--rdp-accent-color: #0055ff;*/
  --rdp-accent-color: #0055ff !important;
}

.rdp{
  margin:.5em  !important;
  height:17em;
}
.rdp-caption{
  font-size: 1rem;
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
  /*font-family: "cursive";*/
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
}
.rdp-button{
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
}

.rdp-head_cell{
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
}


/*mui overrides*/

.MuiPopover-paper{
max-width: calc(100%) !important;
  top:initial !important;
  left:initial !important;
}

.MuiListItemSecondaryAction-root{
  /*top: 50%;*/
  right: 16px !important;
  position: relative !important;
  transform: none !important;
}

.events-control .MuiSvgIcon-root{
 opacity:0
}

.events-control .inner-events-control .MuiSvgIcon-root{
  position: initial;
  opacity:initial;
  /*top: 25px;*/
  /*right: 5px;*/
}

/*testing: eh fuck this thing*/
/*.inner-item .MuiSvgIcon-root{*/
/*  position: relative;*/
/*  top: 25px;*/
/*  right: 5px;*/
/*}*/

.inner-item .MuiSvgIcon-root{
  display:none;
}

.inner-item .inner-skip .MuiSvgIcon-root {
  display:block;
}

/*.MuiListItemText-multiline{*/
/*  min-height:5em !important;*/
/*}*/
/*.MuiCollapse-container .MuiListItemSecondaryAction-root{*/
/*  !*top: 50%;*!*/
/*  right: -4px !important;*/
/*  position: relative !important;*/
/*  transform: none !important;*/
/*}*/



/*.nestedCustom {*/
  /*margin-left:5em !important;*/
/*}*/

.menuHeader {
  paddingLeft: "30px"
}
.list {
  min-width:30em;
}

.chip{
  margin: .1em;
}

.play-events{
  top: 6px;
  position: relative;
  padding-right:.5em;
  display:flex
}
.play-events:hover{
cursor:pointer;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.songkickExt {
  margin-left: auto;
  opacity: .5;
  transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
}
.songkickExt:hover {
  opacity: 1;
}

.fade-out-image {
  opacity: 0;
  width:0;
  height:5em;
  transition:opacity .5s, width .5s
}

.fade-in-image {
  opacity:100%;
  width:5em;
  height:5em;
  transition:opacity .5s, width .5s
}

#events-collapse {
  padding-top:0px !important;
  padding-bottom:0px !important;
}

.MuiCollapse-entered{
  min-height: 0px !important;
  /*height: 39em !important;*/
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-bottom: 1em;
  /*'&::-webkit-scrollbar': width: '0.4em';*/
}

/*::-webkit-scrollbar {*/
/*  width: 1em;*/
/*}*/
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  width:2em;
}
