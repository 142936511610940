@import url(https://fonts.googleapis.com/css?family=Montserrat);
.RotateSpring_text__1mCR5 {
  font-weight: 600;
  font-size: 8em;
  will-change: opacity;
}

.RotateSpring_container__346at {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

/*move devices over to left*/
[class*="_ActionsRSWP"]{
   justify-content: flex-start;
}

/*move text title & artist to right*/
[class*="__nnvdcq"]{
   padding-left: 55px !important;
}

/*only show devices when drawer is expanded*/
[class*="_DevicesRSWP "]{
   visibility: var(--displayDevices);
}


/*testing: move slider to bottom / show only w/ track selected*/
/*(b/c otherwise it's just floating there... yeah idk about this)*/
[class*="_SliderRSWP "]{
   bottom:-96px;
   visibility: var(--displaySlider);
}
/*testing:increase slider size*/
[role="slider"]{
   -webkit-transform: scale(1.5) !important;
           transform: scale(1.5) !important;
}


/*testing:?*/
/*adjust title/artist display for shrinked view*/
/*[class*="_ActionsRSWP"]{*/
/*   margin-left:3em;*/
/*}*/

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    /*width: 120px;*/
    /*height: 120px;*/
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg);}}

*,
*:after,
*:before {
  box-sizing: border-box;
}


.Social_tiles_list__16fBr {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
  segoe ui, arial, sans-serif;
  /*width: 100%;*/
  /*height: 100%;*/
  height:19em !important;
  /*todo: when using scroll here, I have to explicitly set a width? idk*/
  /*width:17em;*/
  width:36em;
  overflow:auto;
  margin-left:2.3em;
  background-color:white;
  margin-top: .5em;
  left: -56px;

  /*todo: somehow when this transform fires, it also fires the translate in social.css ??*/
  /*todo: eh this whole flippy scale thing is just to hard to pull with transitions in there */
  /*transform: scaleX(-1);*/

}

.Social_tiles_list__16fBr > div {
  position: absolute;
  will-change: transform, width, height, opacity;
  padding: 2px;

}

.Social_tiles_list__16fBr > div > div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  /*transform: scaleX(-1);*/
}

.Social_tiles_bg__24tNr {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 5.5em;
  height: 5.5em;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.MuiSelect-select[multiple]{
    /*height:19em !important*/
    height:19em !important
}

/*todo: not seeing application of this*/
.tile-selected{
    border:5px solid grey;
    transition: border .25s;
}


.tile-unselected{
    border: 5px solid transparent;
    /*transition: border .25s;*/
    transition: -webkit-transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out, -webkit-transform 330ms ease-in-out;
}

/*.tile-unselected:hover{*/
/*    border: 5px solid lightgray;*/
/*}*/

.tile-unselected:hover {
    box-shadow: 0 0.5em 0.5em -0.4em black;
    -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em);
        border: 5px solid lightgray;
}

#spring-popper{
    z-index: 1;
    /*todo: can't get position: relative to capture bounds of absolute popper placement*/
    top: .5em !important;
    left: 1em  !important;
}


.drawer {
    display: flex;
    /*justify-content: center;*/
    width: 100%;
    color: white;
    /*overflow-x: hidden;*/
    /* font-size: 25px;
    padding-top: 20px; */
}

.MuiContainer-maxWidthLg{
    padding-right:0px !important;
    padding-left:0px !important;
}

/*.shared{*/
/*    text-transform: uppercase;*/
/*    background:lightgray;*/

/*}*/

.highlight-on{
    transition: background 1s;
    background:#0000002e;
}

.highlight-off{
    transition: background 1s;
    background:initial;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}


.FriendsDisplay_tiles_list__3ZCeL {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
  segoe ui, arial, sans-serif;
  /*width: 100%;*/
  /*height: 100%;*/
  /*height:16em !important;*/

  /*todo: when using scroll here, I have to explicitly set a width? idk*/
  /*width:41em;*/
  width:36em;
  height:18em;
  overflow:hidden;
  /*background-color:white;*/
  margin-top: .5em;
  /*margin-left:-2em;*/

  /*todo: somehow when this transform fires, it also fires the translate in social.css ??*/
  /*todo: eh this whole flippy scale thing is just to hard to pull with transitions in there */
  /*transform: scaleX(-1);*/

}

.FriendsDisplay_tiles_list__3ZCeL > div {
  position: absolute;
  will-change: transform, width, height, opacity;
  padding: 2px;

}

.FriendsDisplay_tiles_list__3ZCeL > div > div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  /*transform: scaleX(-1);*/
}

.FriendsDisplay_tiles_bg__Sj68o {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 5.5em;
  height: 5.5em;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.layered {
    display: grid;

    /*!* Set horizontal alignment of items in, case they have a diffrent width. *!*/
    /*!* justify-items: start | end | center | stretch (default);*!*/
    /*justify-items: center;*/

    /*!* Set vertical alignment of items, in case they have a diffrent height. *!*/
    /*!* align-items: start | end | center | stretch (default); *!*/
    /*align-items: center;*/
}

.layered > * {
    grid-column-start: 1;
    grid-row-start: 1;
}


/* for demonstration purposses only */
/*.layered > * {*/
/*    outline: 1px solid red;*/
/*    background-color: rgba(255, 255, 255, 0.4)*/
/*}*/

.styles_list__1GNH_ {
    position: relative;
    width: 100%;
    height: 100%;
}

.styles_list__1GNH_ > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    /*padding: 15px;*/
}

.styles_list__1GNH_ > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    /*todo: started to run into 'box-shadow stacking?' issue w/ only 2 of the 4 tiles*/
    /*where every run, we stack a bit of box-shadow until it starts consuming everything*/
    /*box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);*/
}

.highcharts-figure, .highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}


input[type="number"] {
    min-width: 50px;
}


/*todo: there's something special about classname = tabs?*/
/*while trying to seperately customize tab widths, couldn't change this tabs to subtabs, tried to make direct descendents rule or something idk*/
/*probably not the way to go about it*/


.tabs0 .MuiContainer-root {
    padding:0px !important;
}
.tabs0 .MuiTab-root {
    min-width:110px !important
}

.tabs1 .MuiContainer-root {
    padding:0px !important;
}
.tabs1 .MuiTab-root {
    min-width:110px !important
}

.tabs2 .MuiContainer-root {
    padding:0px !important;
}
.tabs2 .MuiTab-root {
    min-width:110px !important
}


.MuiAppBar-colorPrimary {
    background-color:grey
}

.accordion header {
    background: #0055ff;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    height: 40px;
    z-index: 10;
    position: relative;
    /*customized */
    margin:0.2em;
    line-height: 2;
    /*top:-2em;*/
    /*margin-bottom: 20px;*/
}

header .MuiSvgIcon-colorSecondary{
    color:white
}



.styles_trailsText__FKqrA {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.styles_trailsText__FKqrA > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.styles_container__2B3kZ {
  /* display: flex;
  align-items: center; */
  height: 100%;
  /* justify-content: center; */
}


#login-button {
    box-sizing: border-box;
    font-family: spotify-circular, Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    background-color: transparent;
    border: 0px;
    border-radius: 1px;
    display: inline-block;
    position: relative;
    text-align: center;
    align-self: center;
    /* width: 9em; */
}
.ButtonInner {
    text-transform: uppercase;
    font-size: inherit;
    font-weight: bold;
    position: relative;
    background-color: #1ed760;
    background-color: var(--background-base, #1ed760);
    color: #000000;
    color: var(--text-base, #000000);
    border-radius: 500px;
    /* note: size of button */
    padding: 7px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item{
    margin-bottom: 2em;
}


/*testing: fix left side cutoff on downsize*/
.app{
  min-width:22em;
}

/*todo: move 'global' stuff somewhere else inside app.js*/
.controls {
  display: flex;
  justify-content: center;
}

.dropdown {
  margin: 16px !important;
  padding: 8px;
  width: 115px !important;
}

.params-container {
  display: flex;
  flex-wrap: wrap;
}

.param-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.param-container button {
  width: 240px;
  margin: 8px;
  text-transform: none;
  font-size: 12px;
}

.nestedListEvents{
  position: relative;
  left: -44em;
  top: 5em;
}

/*testing: shit way to prevent zoom on carousel click (b/c zoomable is broken)*/

.ReactModalPortal{
  display:none !important;
}

/*testing: remove shadowy background*/
[class*="reactCoverCarousel__container"] {
  background:#e2e2e2;
  margin-left: -12px !important;
}

[class*="cover__figure__"] {
  box-shadow:none;
}

/*remove standard blue shading that happens when you select something*/
body {
  -webkit-tap-highlight-color: transparent;
}

/*testing: my own attempts to customize scrollbar*/
/*https://css-tricks.com/custom-scrollbars-in-webkit/*/

/*hide scroll on top level*/
/*::-webkit-scrollbar {*/
/*  !*width: 12px;*!*/
/*  width: 0px;*/
/*  background: transparent;*/
/*}*/

/*!*customize at all other levels*!*/
/*.app ::-webkit-scrollbar {*/
/*  width: 17px;*/
/*}*/

/*body{*/
/*  overflow-y:scroll;*/
/*}*/

/* Handle */

/*.app ::-webkit-scrollbar-thumb {*/
/*  -webkit-border-radius: 10px;*/
/*  border-radius: 10px;*/
/*  background: rgba(255,0,0,0.8);*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
/*}*/
/*.app ::-webkit-scrollbar-thumb:window-inactive {*/
/*  background: rgba(255,0,0,0.4);*/
/*}*/

/*=================================================*/
/*todo: transition is laggy - could be a state/rendering issue tho?*/
/*testing: adjusting width gives transition as long as you don't specify grid-template-column widths*/
/*adjusting style.width of grid item = gets a width transition*/
/*https://stackoverflow.com/questions/19848697/css-grid-where-one-column-shrinks-to-fit-content-the-other-fills-the-remaning-s*/


.separator div:last-child{
  margin-bottom:1em;
}

.defaultGrid {

  /*testing: defaultgrid's height only extends down to cover some of viewpager (the handle)? */
  /*height: 23em;*/

  /*testing: disabling scrollbars here*/
  overflow-y: hidden;
  overflow-x:hidden;

  display: grid;
  background-color:#8080803b;
  /*grid-template-rows: 350px;*/
  /*grid-template-rows: 4em;*/

  /*note: old auto-width*/
  /*grid-template-columns: 40em minmax(53em, 60em) minmax(30em,40em);*/

  /*grid-template-columns: repeat(auto-fit, minmax(30em, 1fr));*/
  /*grid-template-columns: 30em 70em 20em;*/
  /*grid-template-columns:1fr 1fr 1fr;*/

  grid-template-areas:
          "tabs"
          "stats"
          /*"tiles";*/
          /*"events";*/
}

.layered{
  z-index: 1;
}

/*deprecated*/
/*.friendsGrid {*/
/*  display: grid;*/
/*  !*grid-template-columns: 40em 70em 20em;*!*/
/*  grid-template-columns: 40em minmax(45em, 50em) minmax(20em,30em);*/
/*  !*grid-template-rows: 25em 20em 20em;*!*/
/*  grid-template-rows: 25em auto  auto;*/
/*  grid-template-areas:*/
/*    "tabs stats events"*/
/*    "tabs tiles events"*/
/*    "tabs tiles events";*/
/*  !*grid-template-areas:*!*/
/*  !*        "tabs stats events"*!*/
/*  !*        "tiles tiles events"*!*/
/*  !*        "tiles tiles events";*!*/
/*}*/

.wrapper > div {
  border: 1px solid green;
}

/*.header {*/
/*  grid-area: header;*/
/*  background: pink;*/
/*}*/


/*.tiles {*/
/*  grid-area: tiles;*/
/*  background: gray;*/
/*}*/

.tabs {
  grid-area: tabs;
  /*background: red;*/
  transition: width 1s ease-out;
  z-index: 2;
  outline:solid orangered 1px;
  /*height:30em;*/
}
.stats {
  /*width:70em;*/
  background: #54a3ff;
  grid-area: stats;
  transition: height 1s ease-out;
  /*height:20em;*/
  outline:solid orangered 1px;
  /*padding-left:.5em;*/
  /*padding-right:.5em;*/

  /*padding:5px*/
  /*height:20em*/
}

.stats-collapse{
  background: #54a3ff;
  grid-area: stats;
  transition: height 1s ease-out;
  height:4em;

}
.events {
  grid-area: events;
  max-width:40em;
  /*background: purple*/
}


html,
body,
#root {
  height: 100%;
  width: 100%;
  /*max-width: 20em;*/
  overflow-x: hidden;
  position: absolute;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}



/*=================================================*/
/*testing: resizable panes*/

.Resizer {
  /*background: #000;*/
  /*opacity: 0.2;*/
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.tile-selected{
    border:5px solid white;
    transition: border .25s;
}


.tile-unselected{
    border: 5px solid transparent;
    /*transition: border .25s;*/
    transition: -webkit-transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out, -webkit-transform 330ms ease-in-out;
}

/*.tile-unselected:hover{*/
/*    border: 5px solid lightgray;*/
/*}*/

.tile-unselected:hover {
    box-shadow: 0 0.5em 0.5em -0.4em black;
    -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em);
    border: 5px solid lightgray;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}


.list {
    position: relative;
    /*width:20em;*/
    /*height:30em;*/
    /*direction: rtl;*/
    overflow:scroll;
    width: 100%;
}

.list > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    /*direction: rtl;*/
    padding: 15px;
}


.list > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
    transition: -webkit-transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out;
    transition: transform 330ms ease-in-out, -webkit-transform 330ms ease-in-out;
    box-sizing: content-box;
    /*background-color:black;*/
}
.list > div > div:hover,
.list > div > div:focus {
    box-shadow: 0 0.5em 0.5em -0.4em black;
    -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em);
}


.bg {
    position: absolute;
    top: 0;
    left: 0;
    /*width: 100vw;*/
    /*height: 100vh;*/
    width: 5.5em;
    height: 5.5em;
    background-size: cover;
    background-position: center;
    will-change: opacity;
}



.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}

img {
  position: absolute;
  max-width: 100vw;
}

.content {
  position: absolute;
  /* max-width: 100vw; */
  /* margin-left:-8em; */

}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}


.ContextStats_tiles_list__36zbg {
  position: relative;
  /*width:20em;*/
  /*height:30em;*/
  /*direction: rtl;*/
  /*overflow:scroll;*/
  /*height: 37em;*/
  /*testing: experimenting with artist detail drawer on bottom?*/
  height:  25.5em;
  width:22.5em;
}

.ContextStats_tiles_list__36zbg > div {
  position: absolute;
  will-change: transform, width, height, opacity;
  /*direction: rtl;*/
  /*padding: 15px;*/
  padding: 1px;
}


.ContextStats_tiles_list__36zbg > div > div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  transition: -webkit-transform 330ms ease-in-out;
  transition: transform 330ms ease-in-out;
  transition: transform 330ms ease-in-out, -webkit-transform 330ms ease-in-out;
  box-sizing: content-box;
  /*background-color:black;*/
}
.ContextStats_tiles_list__36zbg > div > div:hover,
.ContextStats_tiles_list__36zbg > div > div:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}


.ContextStats_tiles_bg__10DbL {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 5.5em;
  height: 5.5em;
  background-size: cover;
  background-position: center;
  will-change: opacity;
  border:2px solid #6c6161d1;
}

.filterItems{
    margin-left:.5em
}
.filterItems > div{
    margin-bottom:1em;
}

/*.filterItems div:first-child{*/
/*    margin-top:.5em;*/
/*}*/


.wrapper, html, body {
    height: 100%;
    margin: 0;
}
.wrapper {
    display: flex;
    flex-direction: column;
}

.tile-text{
    position: absolute;
    /*bottom: 0;*/
    left: 0;
    right: 0;
    text-align: center;
    font-size: .9em;
    color: white;
    padding: 5px;
    overflow: hidden;
    background: rgba(0, 0, 0, .6);
}


.artist-detail-text{
    position: absolute;
    left: 0;
    right: 0;
    font-size: .9em;
    color: white;
    padding: 5px;
    overflow: hidden;
    background: black;
}

#StackedBarDrill .highcharts-drillup-button {
    display: none
}

.artistInfo-title{
      font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color:darkgrey;
  }
.artistInfo-value{
    font-family: 'Montserrat', sans-serif;
    color:white;
}
.col {
    flex: 1 1;        /* distributes space on the line equally among items */
}

/*misc (anything in Events folder)*/

.display-playlist-params > div{
  margin-bottom: .5em
}

.MuiListItem-divider-123{
  border-bottom:none !important;
}

.MuiListItemText-inset-130:first-child{
  padding-left:0em !important;
}

/*testing: secondary action auto-floats to right*/

/*.rdp{*/
/*  font-size: 1rem;*/
/*  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;*/
/*  font-family: "cursive";*/
/*  font-weight: 400;*/
/*  line-height: 1.5;*/
/*  text-transform: none;*/
/*}*/

:root{
  /*--rdp-accent-color: #0055ff;*/
  --rdp-accent-color: #0055ff !important;
}

.rdp{
  margin:.5em  !important;
  height:17em;
}
.rdp-caption{
  font-size: 1rem;
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
  /*font-family: "cursive";*/
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
}
.rdp-button{
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
}

.rdp-head_cell{
  font-family: Roboto,"Helvetica Neue",Arial,sans-serif !important;
}


/*mui overrides*/

.MuiPopover-paper{
max-width: calc(100%) !important;
  top:initial !important;
  left:initial !important;
}

.MuiListItemSecondaryAction-root{
  /*top: 50%;*/
  right: 16px !important;
  position: relative !important;
  -webkit-transform: none !important;
          transform: none !important;
}

.events-control .MuiSvgIcon-root{
 opacity:0
}

.events-control .inner-events-control .MuiSvgIcon-root{
  position: initial;
  opacity:initial;
  /*top: 25px;*/
  /*right: 5px;*/
}

/*testing: eh fuck this thing*/
/*.inner-item .MuiSvgIcon-root{*/
/*  position: relative;*/
/*  top: 25px;*/
/*  right: 5px;*/
/*}*/

.inner-item .MuiSvgIcon-root{
  display:none;
}

.inner-item .inner-skip .MuiSvgIcon-root {
  display:block;
}

/*.MuiListItemText-multiline{*/
/*  min-height:5em !important;*/
/*}*/
/*.MuiCollapse-container .MuiListItemSecondaryAction-root{*/
/*  !*top: 50%;*!*/
/*  right: -4px !important;*/
/*  position: relative !important;*/
/*  transform: none !important;*/
/*}*/



/*.nestedCustom {*/
  /*margin-left:5em !important;*/
/*}*/

.menuHeader {
  paddingLeft: "30px"
}
.list {
  min-width:30em;
}

.chip{
  margin: .1em;
}

.play-events{
  top: 6px;
  position: relative;
  padding-right:.5em;
  display:flex
}
.play-events:hover{
cursor:pointer;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.songkickExt {
  margin-left: auto;
  opacity: .5;
  transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
}
.songkickExt:hover {
  opacity: 1;
}

.fade-out-image {
  opacity: 0;
  width:0;
  height:5em;
  transition:opacity .5s, width .5s
}

.fade-in-image {
  opacity:100%;
  width:5em;
  height:5em;
  transition:opacity .5s, width .5s
}

#events-collapse {
  padding-top:0px !important;
  padding-bottom:0px !important;
}

.MuiCollapse-entered{
  min-height: 0px !important;
  /*height: 39em !important;*/
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-bottom: 1em;
  /*'&::-webkit-scrollbar': width: '0.4em';*/
}

/*::-webkit-scrollbar {*/
/*  width: 1em;*/
/*}*/
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  width:2em;
}

:root {
    /*--rdp-accent-color: #0000ff;*/
    --rdp-accent-color: #2196f3 !important;
    /*background: rgb(100,181,246);*/
    /*--rdp-accent-color: linear-gradient(0deg, rgba(100,181,246,1) 0%, rgba(25,118,210,1) 48%) !important;*/
}

/*.rdp-day_selected:not([disabled]) {*/
/*    color: white;*/
/*    background-color: #2196f3 !important;*/
/*    !*note: tried to make a gradient for background-color but that's not how gradients work (must be background/background-image)*!*/
/*    !*background:  linear-gradient(0deg, rgba(100,181,246,1) 0%, rgba(25,118,210,1) 48%) !important;*!*/
/*}*/

.rdp-day_range_start{
    background-color: #64b5f6 !important;
    /*background-color: orange !important;*/
}

.rdp-day_range_end{
    background-color: #1976d2 !important;
    /*background-color: red !important;*/
}

/*note: just did a F/R on var(--brand);
 w/ my desired color*/
/*todo: trim out rest of junk here*/
.nowplaying_wrapper__3hNFG{
    text-align:center
}
.nowplaying_widget__3QokG{
    display:inline-flex;
    align-items:center;
    margin:0 auto 30px;
    padding:7px 14px;
    font-weight:500;
    font-size:12px;
    border-radius:30px;
    transition:-webkit-transform .3s ease-out;
    transition:transform .3s ease-out;
    transition:transform .3s ease-out, -webkit-transform .3s ease-out;
    transition:transform .3s ease-out,-webkit-transform .3s ease-out
}
.nowplaying_widget__3QokG:focus,.nowplaying_widget__3QokG:hover{
    -webkit-transform:translateY(-2px);
    transform:translateY(-2px)
}
/* .nowplaying_widget__3QokG:focus span,.nowplaying_widget__3QokG:hover span{
    opacity:0
}
 .nowplaying_widget__3QokG:focus .nowplaying_cover__3GKD9,.nowplaying_widget__3QokG:hover .nowplaying_cover__3GKD9{
    opacity:1
}
 .nowplaying_widgetHidden__os9Tn{
    opacity:0
}
 */
.nowplaying_icon__2aDf-{
    position:relative;
    display:inline-flex;
    justify-content:space-between;
    width:13px;
    height:13px;
    margin-right:8px
}
.nowplaying_icon__2aDf->span{
    width:3px;
    height:100%;
    /*testing: color of animated bars*/
    background-color:black;
    border-radius:3px;
    -webkit-transform-origin:bottom;
    transform-origin:bottom;
    transition:opacity .3s ease-out;
    -webkit-animation:nowplaying_bounce__1cJrH 2.2s ease infinite alternate;
    animation:nowplaying_bounce__1cJrH 2.2s ease infinite alternate;
    content:""
}
.nowplaying_icon__2aDf->span:nth-of-type(2){
    -webkit-animation-delay:-2.2s;
    animation-delay:-2.2s
}
.nowplaying_icon__2aDf->span:nth-of-type(3){
    -webkit-animation-delay:-3.7s;
    animation-delay:-3.7s
}
.nowplaying_cover__3GKD9{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    overflow:hidden;
    border-radius:2px;
    opacity:0;
    transition:opacity .3s ease-out
}
@-webkit-keyframes nowplaying_bounce__1cJrH{
    10%{
        -webkit-transform:scaleY(.3);
        transform:scaleY(.3)
    }
    30%{
        -webkit-transform:scaleY(1);
        transform:scaleY(1)
    }
    60%{
        -webkit-transform:scaleY(.5);
        transform:scaleY(.5)
    }
    80%{
        -webkit-transform:scaleY(.75);
        transform:scaleY(.75)
    }
    to{
        -webkit-transform:scaleY(.6);
        transform:scaleY(.6)
    }
}
@keyframes nowplaying_bounce__1cJrH{
    10%{
        -webkit-transform:scaleY(.3);
        transform:scaleY(.3)
    }
    30%{
        -webkit-transform:scaleY(1);
        transform:scaleY(1)
    }
    60%{
        -webkit-transform:scaleY(.5);
        transform:scaleY(.5)
    }
    80%{
        -webkit-transform:scaleY(.75);
        transform:scaleY(.75)
    }
    to{
        -webkit-transform:scaleY(.6);
        transform:scaleY(.6)
    }
}
.page_container__FfpE7{
    max-width:var(--siteWidth);
}

ellipse:hover{
    cursor:pointer;
}

.selected{
    background-color:#80808026
}
.unselected{
    background-color:initial;
}

body {
  font-family: system-ui;
  margin: 0;
  padding: 0;
  /*background-color: black;*/
}


