.accordion header {
    background: #0055ff;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    height: 40px;
    z-index: 10;
    position: relative;
    /*customized */
    margin:0.2em;
    line-height: 2;
    /*top:-2em;*/
    /*margin-bottom: 20px;*/
}

header .MuiSvgIcon-colorSecondary{
    color:white
}
