
/*testing: fix left side cutoff on downsize*/
.app{
  min-width:22em;
}

/*todo: move 'global' stuff somewhere else inside app.js*/
.controls {
  display: flex;
  justify-content: center;
}

.dropdown {
  margin: 16px !important;
  padding: 8px;
  width: 115px !important;
}

.params-container {
  display: flex;
  flex-wrap: wrap;
}

.param-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.param-container button {
  width: 240px;
  margin: 8px;
  text-transform: none;
  font-size: 12px;
}

.nestedListEvents{
  position: relative;
  left: -44em;
  top: 5em;
}

/*testing: shit way to prevent zoom on carousel click (b/c zoomable is broken)*/

.ReactModalPortal{
  display:none !important;
}

/*testing: remove shadowy background*/
[class*="reactCoverCarousel__container"] {
  background:#e2e2e2;
  margin-left: -12px !important;
}

[class*="cover__figure__"] {
  box-shadow:none;
}

/*remove standard blue shading that happens when you select something*/
body {
  -webkit-tap-highlight-color: transparent;
}

/*testing: my own attempts to customize scrollbar*/
/*https://css-tricks.com/custom-scrollbars-in-webkit/*/

/*hide scroll on top level*/
/*::-webkit-scrollbar {*/
/*  !*width: 12px;*!*/
/*  width: 0px;*/
/*  background: transparent;*/
/*}*/

/*!*customize at all other levels*!*/
/*.app ::-webkit-scrollbar {*/
/*  width: 17px;*/
/*}*/

/*body{*/
/*  overflow-y:scroll;*/
/*}*/

/* Handle */

/*.app ::-webkit-scrollbar-thumb {*/
/*  -webkit-border-radius: 10px;*/
/*  border-radius: 10px;*/
/*  background: rgba(255,0,0,0.8);*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
/*}*/
/*.app ::-webkit-scrollbar-thumb:window-inactive {*/
/*  background: rgba(255,0,0,0.4);*/
/*}*/

/*=================================================*/
/*todo: transition is laggy - could be a state/rendering issue tho?*/
/*testing: adjusting width gives transition as long as you don't specify grid-template-column widths*/
/*adjusting style.width of grid item = gets a width transition*/
/*https://stackoverflow.com/questions/19848697/css-grid-where-one-column-shrinks-to-fit-content-the-other-fills-the-remaning-s*/


.separator div:last-child{
  margin-bottom:1em;
}

.defaultGrid {

  /*testing: defaultgrid's height only extends down to cover some of viewpager (the handle)? */
  /*height: 23em;*/

  /*testing: disabling scrollbars here*/
  overflow-y: hidden;
  overflow-x:hidden;

  display: grid;
  background-color:#8080803b;
  /*grid-template-rows: 350px;*/
  /*grid-template-rows: 4em;*/

  /*note: old auto-width*/
  /*grid-template-columns: 40em minmax(53em, 60em) minmax(30em,40em);*/

  /*grid-template-columns: repeat(auto-fit, minmax(30em, 1fr));*/
  /*grid-template-columns: 30em 70em 20em;*/
  /*grid-template-columns:1fr 1fr 1fr;*/

  grid-template-areas:
          "tabs"
          "stats"
          /*"tiles";*/
          /*"events";*/
}

.layered{
  z-index: 1;
}

/*deprecated*/
/*.friendsGrid {*/
/*  display: grid;*/
/*  !*grid-template-columns: 40em 70em 20em;*!*/
/*  grid-template-columns: 40em minmax(45em, 50em) minmax(20em,30em);*/
/*  !*grid-template-rows: 25em 20em 20em;*!*/
/*  grid-template-rows: 25em auto  auto;*/
/*  grid-template-areas:*/
/*    "tabs stats events"*/
/*    "tabs tiles events"*/
/*    "tabs tiles events";*/
/*  !*grid-template-areas:*!*/
/*  !*        "tabs stats events"*!*/
/*  !*        "tiles tiles events"*!*/
/*  !*        "tiles tiles events";*!*/
/*}*/

.wrapper > div {
  border: 1px solid green;
}

/*.header {*/
/*  grid-area: header;*/
/*  background: pink;*/
/*}*/


/*.tiles {*/
/*  grid-area: tiles;*/
/*  background: gray;*/
/*}*/

.tabs {
  grid-area: tabs;
  /*background: red;*/
  transition: width 1s ease-out;
  z-index: 2;
  outline:solid orangered 1px;
  /*height:30em;*/
}
.stats {
  /*width:70em;*/
  background: #54a3ff;
  grid-area: stats;
  transition: height 1s ease-out;
  /*height:20em;*/
  outline:solid orangered 1px;
  /*padding-left:.5em;*/
  /*padding-right:.5em;*/

  /*padding:5px*/
  /*height:20em*/
}

.stats-collapse{
  background: #54a3ff;
  grid-area: stats;
  transition: height 1s ease-out;
  height:4em;

}
.events {
  grid-area: events;
  max-width:40em;
  /*background: purple*/
}


html,
body,
#root {
  height: 100%;
  width: 100%;
  /*max-width: 20em;*/
  overflow-x: hidden;
  position: absolute;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}



/*=================================================*/
/*testing: resizable panes*/

.Resizer {
  /*background: #000;*/
  /*opacity: 0.2;*/
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
