*,
*:after,
*:before {
  box-sizing: border-box;
}


.list {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
  segoe ui, arial, sans-serif;
  /*width: 100%;*/
  /*height: 100%;*/
  height:19em !important;
  /*todo: when using scroll here, I have to explicitly set a width? idk*/
  /*width:17em;*/
  width:36em;
  overflow:auto;
  margin-left:2.3em;
  background-color:white;
  margin-top: .5em;
  left: -56px;

  /*todo: somehow when this transform fires, it also fires the translate in social.css ??*/
  /*todo: eh this whole flippy scale thing is just to hard to pull with transitions in there */
  /*transform: scaleX(-1);*/

}

.list > div {
  position: absolute;
  will-change: transform, width, height, opacity;
  padding: 2px;

}

.list > div > div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  /*transform: scaleX(-1);*/
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 5.5em;
  height: 5.5em;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}
