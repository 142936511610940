.MuiSelect-select[multiple]{
    /*height:19em !important*/
    height:19em !important
}

/*todo: not seeing application of this*/
.tile-selected{
    border:5px solid grey;
    transition: border .25s;
}


.tile-unselected{
    border: 5px solid transparent;
    /*transition: border .25s;*/
    transition: transform 330ms ease-in-out;
}

/*.tile-unselected:hover{*/
/*    border: 5px solid lightgray;*/
/*}*/

.tile-unselected:hover {
    box-shadow: 0 0.5em 0.5em -0.4em black;
    transform: translateY(-0.25em);
        border: 5px solid lightgray;
}

#spring-popper{
    z-index: 1;
    /*todo: can't get position: relative to capture bounds of absolute popper placement*/
    top: .5em !important;
    left: 1em  !important;
}


.drawer {
    display: flex;
    /*justify-content: center;*/
    width: 100%;
    color: white;
    /*overflow-x: hidden;*/
    /* font-size: 25px;
    padding-top: 20px; */
}

.MuiContainer-maxWidthLg{
    padding-right:0px !important;
    padding-left:0px !important;
}

/*.shared{*/
/*    text-transform: uppercase;*/
/*    background:lightgray;*/

/*}*/

.highlight-on{
    transition: background 1s;
    background:#0000002e;
}

.highlight-off{
    transition: background 1s;
    background:initial;
}
