.list {
    position: relative;
    width: 100%;
    height: 100%;
}

.list > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    /*padding: 15px;*/
}

.list > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    /*todo: started to run into 'box-shadow stacking?' issue w/ only 2 of the 4 tiles*/
    /*where every run, we stack a bit of box-shadow until it starts consuming everything*/
    /*box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);*/
}
